import React, { useState, useEffect } from 'react'
import { PiStudent } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";
import { getAllStudents } from '../redux/apiCalls/StudentsApiCalls';
import { Average, getAllTeachers, getCount } from '../redux/apiCalls/TeachersApiCalls';
import { FaUniversity } from "react-icons/fa";
import { FaPlaceOfWorship } from "react-icons/fa";
import { FaSearchLocation } from "react-icons/fa";
import { FaHandsHoldingChild } from "react-icons/fa6";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoQrCodeSharp } from "react-icons/io5";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { MdPriceChange } from "react-icons/md";
import { getIdCookie } from "../utils/cookies";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  { name: 'Jan', uv: 2.5, pv: 2, amt: 2400 },
  { name: 'Feb', uv: 2, pv: 2.5, amt: 2400 },
  { name: 'Mar', uv: 2.5, pv: 2, amt: 2400 },
  { name: 'Apr', uv: 2, pv: 2.5, amt: 2400 },
  { name: 'May', uv: 2.5, pv: 2, amt: 2400 },
  { name: 'Jun', uv: 2, pv: 2.5, amt: 2400 },
  { name: 'Jul', uv: 2.5, pv: 2, amt: 2400 },
];
export default function Main() {

  const dispatch = useDispatch()
  
  const {numberOfStudents} = useSelector((state) => state.student)
  const {numberOfTeachers} = useSelector((state) => state.teacher)
  const {count} = useSelector((state) => state.teacher)
  const {average} = useSelector((state) => state.teacher)
  
  console.log({average:average.averagePrice})

  const role = getIdCookie()?.role

  useEffect(() => {    
    dispatch(getAllStudents())
    dispatch(getAllTeachers())
    dispatch(getCount())
    dispatch(Average())
  }, [dispatch])


  return (
    <div className={`w-[100%] h-[100%] bg-[#f6f6f6] dark:bg-slate-900  `}>
    <div className="w-[100%] h-[10%] flex justify-between items-center">
      <div className="flex items-center w-[50%]">
        <p
          className="pl-2
               border-l-[1px] border-[#d6dce1] dark:text-white  text-[#4b4b4b] text-[1.714rem] tracking-[1.2]"
        >
          الرئيسية
        </p>
        <p className="mr-3 dark:text-white">الرئيسية</p>
      </div>
    </div>
    <div className='w-[100%] h-[20%] mt-4'>
      <div className='flex w-[100%] h-[100%] justify-center items-center gap-3'>
        <div className='bg-white dark:bg-slate-800 w-[25%] h-[90px] rounded-lg p-4 shadow-lg'>
          <div className='flex justify-between items-center h-[100%] w-[100%]'>
            <div>
              <p className='text-xl font-bold dark:text-white mb-2'>{numberOfStudents}</p>
              <p className=' text-slate-400 dark:text-white text-lg'>عدد الطلاب</p>
            </div>
            <div className=' bg-lime-200 text-3xl rounded-3xl p-1'>
              <PiStudent />
            </div>
          </div>
        </div>
        <div className='bg-white dark:bg-slate-800 w-[25%] h-[90px] rounded-lg p-4 shadow-lg'>
          <div className='flex justify-between items-center h-[100%] w-[100%]'>
            <div>
              <p className='text-xl font-bold dark:text-white mb-2'>{numberOfTeachers}</p>
              <p className=' text-slate-400 dark:text-white text-lg'>عدد الاساتذة</p>
            </div>
            <div className=' bg-purple-200 text-3xl rounded-3xl p-1'>
              <FaChalkboardTeacher />
            </div>
          </div>
        </div>
        <div className='bg-white dark:bg-slate-800 w-[25%] h-[90px] rounded-lg p-4 shadow-lg'>
          <div className='flex justify-between items-center h-[100%] w-[100%]'>
            <div>
              <p className='text-xl font-bold dark:text-white mb-2'>0</p>
              <p className=' text-slate-400 dark:text-white text-lg'>عدد المعاهد</p>
            </div>
            <div className=' bg-slate-200 text-3xl rounded-3xl p-1'>
              <FaPlaceOfWorship />
            </div>
          </div>
        </div>
        <div className='bg-white dark:bg-slate-800 w-[25%] h-[90px] rounded-lg p-4 shadow-lg'>
          <div className='flex justify-between items-center h-[100%] w-[100%]'>
            <div>
              <p className='text-xl dark:text-white font-bold mb-2'>0</p>
              <p className=' text-slate-400 dark:text-white text-lg'>عدد الجامعات</p>
            </div>
            <div className=' bg-purple-200 text-3xl rounded-3xl p-1'>
              <FaUniversity />
            </div>
          </div>
        </div>
        
        <div className='bg-white dark:bg-slate-800 w-[25%] h-[90px] rounded-lg p-4 shadow-lg'>
          <div className='flex justify-between items-center h-[100%] w-[100%]'>
            <div>
              <p className='text-xl dark:text-white font-bold mb-2'>{count}</p>
              <p className=' text-slate-400 dark:text-white text-lg'>عدد الطلبات</p>
            </div>
            <div className=' bg-purple-200 text-3xl rounded-3xl p-1'>
              <RiAccountPinCircleLine />
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className='w-[100%] h-[80%] flex justify-center items-center'>
        <div className="w-[100%] h-1/5 mt-4 flex justify-center items-center">
          <div className="bg-white dark:bg-slate-800 relative flex w-3/4 h-[22rem] rounded-lg p-12 shadow-md">
          <h2 className="text-start px-4 dark:text-white text-lg text-slate-500 absolute top-0 right-0 w-[100%]">
        الأرباح الشهرية
          </h2>
            <ResponsiveContainer width="100%" height="100%" className='w-[70%]'>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
            <div className='w-[30%] flex justify-center items-center h-[100%] flex-col space-y-6'>
              <p className='text-xl font-light dark:text-white'>الربح السنوي</p>
              <p className='text-xl dark:text-white'>900</p>
            </div>
          </div>
        </div>
        <div className='w-[30%] h-[100%] mt-4'>
        <div className='flex flex-col w-[100%] h-[100%] justify-center items-center gap-3'>
          <div className='bg-white dark:bg-slate-800 w-[100%] h-[90px] rounded-lg p-4 shadow-lg'>
            <div className='flex justify-between items-center h-[100%] w-[100%]'>
              <div>
                <p className='text-xl font-bold dark:text-white mb-2'>0</p>
                <p className=' text-slate-400 dark:text-white text-lg'>عدد الروضات</p>
              </div>
              <div className=' bg-slate-200 text-3xl rounded-3xl p-1'>
                <FaHandsHoldingChild />
              </div>
            </div>
          </div>
          <div className='bg-white dark:bg-slate-800 w-[100%] h-[90px] rounded-lg p-4 shadow-lg'>
            <div className='flex justify-between items-center h-[100%] w-[100%]'>
              <div>
                <p className='text-xl font-bold dark:text-white mb-2'>0</p>
                <p className=' text-slate-400 dark:text-white text-lg'>نقاط البيع</p>
              </div>
              <div className=' bg-yellow-200 text-3xl rounded-3xl p-1'>
                <FaSearchLocation />
              </div>
            </div>
          </div>
          {role === 'superAdmin' ? (<div className='bg-white dark:bg-slate-800 w-[100%] h-[90px] rounded-lg p-4 shadow-lg'>
            <div className='flex justify-between items-center h-[100%] w-[100%]'>
              <div>
                <p className='text-xl font-bold dark:text-white mb-2'>{average.averagePrice}</p>
                <p className=' text-slate-400 dark:text-white text-lg'>السعر الوسطي</p>
              </div>
              <div className=' bg-orange-300 text-3xl rounded-3xl p-1'>
                <MdPriceChange />
              </div>
            </div>
          </div>) : null}
          <div className='bg-white dark:bg-slate-800 w-[100%] h-[90px] rounded-lg p-4 shadow-lg'>
            <div className='flex justify-between items-center h-[100%] w-[100%]'>
              <div>
                <p className='text-xl font-bold dark:text-white mb-2'>0</p>
                <p className=' text-slate-400 dark:text-white text-lg'>الاكواد المباعة</p>
              </div>
              <div className=' bg-blue-200 text-3xl rounded-3xl p-1'>
                <IoQrCodeSharp />
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    <div>
    </div>
  </div>
  )
}
