import React from "react";
import { dotSpinner } from 'ldrs'
dotSpinner.register()
export default function Loading(props) {
  return (
    <l-dot-spinner
    size="20"
    speed="0.9" 
    color="white" 
  ></l-dot-spinner>
  );
}

