import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { subjectsAction } from "../slices/subjectSlice";

export function getAllSubjects() {
  return async (dispatch) => {

    dispatch(subjectsAction.setLoading());
    try {
      const { data } = await request.get(`/subjects`);
      dispatch(subjectsAction.setSubject(data?.subjects));// Corrected payload
      dispatch(subjectsAction.setNumberOfSubjects(data?.documentCount))
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(subjectsAction.setLoading());
    }
  };
}

export function deleteSubject(id) {
  return async (dispatch) => {

    dispatch(subjectsAction.setLoading());
    try {
      await request.delete(`/subjects/${id}`);
      toast.success("تم حذف المادة");

    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.log(error);
    } finally {
      dispatch(subjectsAction.setLoading());
    }
  };
}

export function createSubject(data) {
  return async (dispatch) => {

    dispatch(subjectsAction.setLoading());
    try {
      await request.post(`/subjects`, data);
      toast.success("تم اضاغة المادة");
    } catch (error) {
      toast.error("لم يتم اضاغة المادة");
      console.log(error);
    } finally {
      dispatch(subjectsAction.setLoading());
    }
  };
}

export function editSubject(data, id) {
  return async (dispatch) => {

    dispatch(subjectsAction.setLoading());
    try {
      await request.put(`/subjects/${id}`, data);
      toast.success("تم تعديل المادة");
    } catch (error) {
      toast.error("لم يتم تعديل المادة");
      console.log(error);
    } finally {
      dispatch(subjectsAction.setLoading());
    }
  };
}

export function getSubject(id) {
  return async (dispatch) => {

    dispatch(subjectsAction.setLoading());
    try {
      const {data} = await request.get(`/subjects/${id}`);
      dispatch(subjectsAction.getSubjectdata(data))
      console.log({subject: data})
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(subjectsAction.setLoading());
    }
  };
}