import { createSlice } from "@reduxjs/toolkit";

const teacherSlice = createSlice({
  name: "teachers",
  initialState: {
    teachers: [],
    numberOfTeachers: 0,
    isOpenedTeacher: false,
    loading: false,
    teacher: null,
    average: 0,
    count: 0,
  },
  reducers: {
    setTeacher(state, action) {
      state.teachers = action.payload;
    },
    setNumberOfTeacher(state, action) {
      state.numberOfTeachers = action.payload;
    },
    removeTeacher(state, action) {
      const noti = action.payload;
      state.teachers = state.teachers.filter((teacher) => teacher.id !== noti);
    },
    setIsOpenedTeacher(state) {
      state.isOpenedTeacher = !state.isOpenedTeacher;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
    addTeacher(state, action) {
      state.teachers.push(action.payload);
    },
    teacher(state, action) {
      state.teacher = action.payload;
    },
    count(state, action) {
      state.count = action.payload;
    },
    setAverage(state, action) {
      state.average = action.payload;
    },
  },
});

const teachersReducer = teacherSlice.reducer;
const teachersAction = teacherSlice.actions;

export { teachersAction, teachersReducer };
