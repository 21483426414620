// import React from 'react'

// const Messages = () => {
//   return (
//     <div className='messages'>Messages</div>
//   )
// }

// export default Messages



import React, { useState, useEffect }  from 'react'
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { GoTrash } from "react-icons/go";
import { getAllStudents, removeStudent } from '../redux/apiCalls/StudentsApiCalls';
import { useSelector, useDispatch } from "react-redux";
import Layout from '../Components/Layout';
import { Pagination } from '../Components/Pagination';
import CreateStudent from '../Components/CreateStudent';

const PER_PAGE = 7;

export default function Messages() {
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const {students, numberOfStudents } = useSelector(state => state.student)
  

    const dispatch = useDispatch()

    useEffect(() => {    
        dispatch(getAllStudents(currentPage, PER_PAGE))
    }, [dispatch, currentPage])

      
      const page = Math.ceil(numberOfStudents / PER_PAGE)

  return (
    <div className={`w-[100%] h-[100%] ${open ? "overflow-hidden" : ""}`}>
    {open && <Layout open={open} setOpen={setOpen}/>}
    <CreateStudent open={open} setOpen={setOpen} />
    <div className="w-[100%] h-[10%] flex justify-between items-center">
      <div className="flex items-center w-[50%] mb-4">
        <p
          className="pl-2
               border-l-[1px] border-[#d6dce1]  text-[#4b4b4b] dark:text-white text-[1.714rem] tracking-[1.2]"
        >
           راسلنا
        </p>
        <p className="mr-3 dark:text-white">الرسائل الواردة</p>
      </div>
      <div className="flex items-center w-[50%]">
        
      </div>
    </div>
    <div className="flex justify-center w-[100%]">
      <div className="w-[100%]">
      <table className="w-full bg-white dark:bg-slate-900 border border-gray-300 dark:border-slate-600">
    <thead>
      <tr className="text-slate-400 dark:text-white text-sm text-center">
        <th className="py-2 px-4 border-b">الجهة</th>
        <th className="py-2 px-4 border-b">النص</th>
        <th className="py-2 px-4 border-b">اسم المستخدم</th>
        <th className="py-2 px-4 border-b">تاريخ الارسال</th>
        <th className="py-2 px-4 border-b">...</th>
      </tr>
    </thead>
    <tbody>
    {students?.map((student) => (
      <tr key={student} className="text-center dark:text-white">
      <td className="py-2 px-4 border-b">{student.phoneNumber}</td>
      <td className="py-4 px-6 border-b">{student?.userName}</td>
      <td className="py-4 px-6 border-b">{}</td>
      <td className="py-4 px-6 border-b">{}</td>
      <td className="py-2 px-4 border-b"><GoTrash className="mx-auto text-red-400 cursor-pointer" onClick={() => dispatch(removeStudent(student.id)).then(() => dispatch(getAllStudents()))} /></td>
    </tr>
    ))}
    </tbody>
  </table>
      </div>
    </div>
    <Pagination pages={page} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
  </div>
  )
}
