import React, { useState, useEffect }  from 'react'
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { GoTrash } from "react-icons/go";
import { getAllStudents, removeStudent } from '../redux/apiCalls/StudentsApiCalls';
import { useSelector, useDispatch } from "react-redux";
import Layout from '../Components/Layout';
import { Pagination } from '../Components/Pagination';
import CreateStudent from '../Components/CreateStudent';

const PER_PAGE = 7;

export default function Students() {
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const {students, numberOfStudents } = useSelector(state => state.student)
    const [searchValue, setSearchValue] = useState("");



    const HandlerSearch = () => {
      console.log({ searchValue: searchValue });
      setCurrentPage(1)
    };

    const dispatch = useDispatch()

    console.log({students:students})
    useEffect(() => {    
        dispatch(getAllStudents(currentPage, PER_PAGE, searchValue))
    }, [dispatch, currentPage, searchValue])

      
      const page = Math.ceil(numberOfStudents / PER_PAGE)

  return (
    <div className={`w-[100%] h-[100%] ${open ? "overflow-hidden" : ""}`}>
    {open && <Layout open={open} setOpen={setOpen}/>}
    <CreateStudent open={open} setOpen={setOpen} />
    <div className="w-[100%] h-[10%] flex justify-between items-center">
      <div className="flex items-center w-[50%]">
        <p
          className="pl-2
               border-l-[1px] border-[#d6dce1] dark:text-white  text-[#4b4b4b] text-[1.714rem] tracking-[1.2]"
        >
          الطلاب
        </p>
        <p className="mr-3 dark:text-white">الطلاب</p>
      </div>
      <div className="flex items-center w-[50%]">
        <div className="flex flex-1">
          <div className="w-[87%]">
            <input
              placeholder="ابحث عن طالب ..."
              className="w-[100%] text-[1rem] text-[#6e6b7b] font-[400] tracking-[1.45] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] border-l-0 rounded-[0.357rem] rounded-l-none outline-none"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className=" bg-[#fff] p-[9px] border-[1px] border-[#d8d6de]  rounded-[0.357rem] rounded-r-none border-r-0">
            <div className="union"  onClick={HandlerSearch}>
              <IoIosSearch
                className="cursor-pointer text-[#6e6b7b]"
                size={20}
              />
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              setOpen(true);
            }}
            className="text-[1em] w-[159px] flex items-center bg-[#7367f0] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] border-none outline-none rounded-[0.357rem] text-[#fff]"
          >
            إضافة مستخدم
            <div className="union mr-3">
              <FaPlus size={15} />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div className="flex justify-center w-[100%] mt-4">
      <div className="w-[100%]">
      <table className="w-full bg-white border dark:bg-slate-800 border-gray-300">
    <thead>
      <tr className="text-slate-400 dark:text-white text-sm text-center">
        <th className="py-2 px-4 border-b">اسم المستخدم</th>
        <th className="py-2 px-4 border-b">رقم المستخدم</th>
        <th className="py-2 px-4 border-b">هوايات</th>
        <th className="py-2 px-4 border-b">عدد المحاولات</th>
        <th className="py-2 px-4 border-b">المستوى الأكاديمي</th>
        <th className="py-2 px-4 border-b">...</th>
      </tr>
    </thead>
    <tbody>
    {students?.map((student) => (
      <tr key={student} className="text-center dark:text-white">
      <td className="py-4 px-6 border-b">{student?.userName}</td>
      <td className="py-2 px-4 border-b">{student?.phoneNumber}</td>
      <td className="py-2 px-4 border-b">...</td>
      <td className="py-2 px-4 border-b">...</td>
      <td className="py-2 px-4 border-b">{student?.academicLevelName}</td>
      <td className="py-2 px-4 border-b"><GoTrash className="mx-auto text-red-400 cursor-pointer" onClick={() => dispatch(removeStudent(student.id)).then(() => dispatch(getAllStudents(currentPage, PER_PAGE)))} /></td>
    </tr>
    ))}
    </tbody>
  </table>
      </div>
    </div>
    <Pagination pages={page} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
  </div>
  )
}
