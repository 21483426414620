import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./Pages/Login";
import Home from "./Components/Home";
import Main from "./Pages/Main";
import Students from "./Pages/Students";
import NotificationsPage from "./Pages/NotificationsPage";
import Teachers from "./Pages/Teachers";
import Messages from "./Pages/Messages";
import AccountStatement from "./Pages/AccountStatement";
import Dashboard from "./Pages/Dashboard";
import Methods from "./Pages/Methods";

const router = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { index: true, element: <Main /> },
      { path: "main", element: <Main /> },
      { path: "student", element: <Students /> },
      { path: "notifications", element: <NotificationsPage /> },
      { path: "teachers", element: <Teachers /> },
      { path: "messages", element: <Messages /> },
      { path: "account-statement", element: <AccountStatement /> },
      { path: "method", element: <Methods /> },

    ],
  },
  {
    path: "/",
    element: <Home />,
    children: [{ index: true, element: <Login /> }],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
