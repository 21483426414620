import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { teachersAction } from "../slices/teachers";

export function getAllTeachers(
  currentPage,
  PER_PAGE,
  selectedSubject,
  selectedLocation,
  selectAcademic,
  selectGender,
  selectActive,
  selectPhoneNumber,
  selectMinPrice,
  selectMaxPrice,
  filter,
  searchValue
  ) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());

    try {
      const queryParameters = new URLSearchParams();
      if(filter){
        if (selectedSubject) queryParameters?.append("subjectIds", selectedSubject);
        if (selectedLocation) queryParameters?.append("locationIds", selectedLocation);
        if (selectAcademic) queryParameters?.append("academicLevelIds", selectAcademic);
        if (selectGender) queryParameters?.append("genderName", selectGender);
        if (selectMinPrice) queryParameters?.append("minPrice", selectMinPrice);
        if (selectMaxPrice) queryParameters?.append("maxPrice", selectMaxPrice);
        if (selectActive) queryParameters?.append("active", selectActive);
        if (selectPhoneNumber) queryParameters?.append("number", selectPhoneNumber);

      }else {
        if (searchValue) queryParameters?.append("userName", searchValue);
      }
      
      if (currentPage) queryParameters?.append("page", currentPage);
      if (PER_PAGE) queryParameters?.append("perPage", PER_PAGE);

      const { data } = await request.get(
        `/users/teachers?${queryParameters.toString()}`
      );
      console.log( `/users/teachers?${queryParameters.toString()}`)
      dispatch(teachersAction.setTeacher(data?.users));
      dispatch(teachersAction.setNumberOfTeacher(data?.documentCount));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function removeTeacher(teacherId) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      await request.delete(`/users/profile/${teacherId}`);
      dispatch(teachersAction.removeTeacher(teacherId)); // Corrected payload
      toast.success("تم حذف المستخدم");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function Average() {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      const {data} = await request.get(`/users/average`);
      dispatch(teachersAction.setAverage(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function getCount() {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      const {data} = await request.get(`/users/count`);
      dispatch(teachersAction.count(data.total)); // Corrected payload
      console.log({count: data})
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function addTeacher(info, setOpen) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      const { data } = await request.post(`/auth/register`, info);
      dispatch(teachersAction.addTeacher(data?.user)); // Corrected payload
      toast.success(`تمت اضافة مستخدم`);
      setOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function EditTeacher(id, info) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      await request.put(`/users/profile/${id}`, info);
      console.log({info: info})
      toast.success(`تمت تعديل المستخدم`);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message);

    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function activeOfTeacher(id) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {
      const {data} = await request.put(`/users/profile/ok/${id}`);
      console.log(data)
      toast.success(data.message);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}

export function ProfileTeacher(id) {
  return async (dispatch) => {
    dispatch(teachersAction.setLoading());
    try {

      const {data} = await request.put(`/users/profile/${id}`);
      dispatch(teachersAction.teacher(data)); 

    } catch (error) {
      console.log(error);
    } finally {
      dispatch(teachersAction.setLoading());
    }
  };
}