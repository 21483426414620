import React,{useEffect, useState} from 'react'
import { getIdCookie } from "../utils/cookies";
import { CiDark } from "react-icons/ci";
import { CiLight } from "react-icons/ci";
import { deleteIdCookie } from "../utils/cookies";
  import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

export default function Header() {

  const element = document.documentElement

  const navigate = useNavigate();
  const [thread, setThread] = useState(true)
  const [logOut, setLogOut] = useState(false)

  useEffect(() => {
    switch(thread) {
      case false:
        element.classList.add('dark')
        break;
      case true:
        element.classList.remove('dark')
        break;
      default: 
        break;
    }
  })

  return (
    <div className='w-[100%] h-[100%] p-6'>
            <div className='w-[100%] h-[100%] bg-white dark:bg-slate-800 shadow-md rounded-lg flex justify-between items-center px-4'>
              <div className='flex space-x-2 justify-center items-center'>
                <div onClick={() => setThread(!thread)}>
                {thread ? <CiLight className='text-3xl' /> : <CiDark className='text-3xl dark:text-white' />}

                </div>
              </div>

              <div className='flex relative space-x-2 group justify-center items-center'>
                <div className='' onClick={() => setLogOut(!logOut)}>
                  <p className='dark:text-white'>{getIdCookie()?.userName}</p>
                  <div className='flex justify-between items-center'>
                  <p><FaChevronRight className='dark:text-white cursor-pointer' /></p>

                    <p className='text-[#7367f0] '>{getIdCookie()?.role}</p>
                  </div>
                </div>
                <div className={` absolute left-[158px] cursor-pointer bg-[#7367f0] shad  w-[100%] h-[3rem] ${logOut ? "flex" : "hidden"} justify-center items-center`} onClick={() => {
                  deleteIdCookie();
                  navigate("/", { replace: true });
                }}>
                  <p className='text-white'>تسجيل الخروج</p>
                </div>
                <img src="/assests/FYT Logo-08.png" alt=""  className={` w-[50px] group-hover:w-[50px]  ease duration-[1s]`}/>
              </div>
            </div>
          </div>
  )
}
