import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slices/authSlice";
import { notificationsReducer } from "./slices/notificationsSlice";
import { teachersReducer } from "./slices/teachers";
import { studentsReducer } from "./slices/students";
import { subjectsReducer } from "./slices/subjectSlice";
import { locationsReducer } from "./slices/locationSlice";
import { acadimicsReducer } from "./slices/acadimicSlice";
import { historyReducer } from "./slices/historySlice";

const store = configureStore({
  reducer: {
    admin: userReducer,
    subject: subjectsReducer,
    acadimic: acadimicsReducer,
    location: locationsReducer,
    teacher: teachersReducer,
    student: studentsReducer,
    notifications: notificationsReducer,
    history: historyReducer,
  },
});

export default store;
