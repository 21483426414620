import React, { useState } from 'react'

const Notification = ({notification,  isChecked, handleCheckboxChange}) => {
  return (
    <div className='w-[100%] bg-[#fff] text-[1rem] font-[400] tracking-[1.45] text-[#6e6b7b] rounded-[8px] shadow-md mb-[1rem]'>
        <div className='pr-[48px] pl-[48px] pt-[6px] flex items-center justify-between text-[1rem] font-[400] tracking-[1.45] text-[#6e6b7b]'>
            <p>
                {notification?.title}</p>
            <div className='flex items-center'>
                <p className='ml-[10px]'>مرسل الى {notification?.allUsers || notification?.userNumber}
                </p>
                <p>1/4/2003 10:45</p>
            </div>
        </div>
        <div className='p-[12px] flex pr-0'>
            <div className='pr-[1rem] pl-[12px]'>
                <input checked={isChecked}
            onChange={() => handleCheckboxChange(notification.id)}type="checkbox" className='w-[1.285rem] h-[1.285rem] cursor-pointer'/>
            </div>
            <p>{notification?.body}</p>
        </div>
    </div>
  )
}

export default Notification