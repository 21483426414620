import React, { useState, useEffect } from "react";
import { getAllStudents } from "../redux/apiCalls/StudentsApiCalls";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Components/Layout";
import { Pagination } from "../Components/Pagination";
import CreateStudent from "../Components/CreateStudent";
import { getAllHistory } from "../redux/apiCalls/historyApiCall";

const PER_PAGE = 7;
export default function AccountStatement() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { history, numberOfHistory } = useSelector((state) => state.history);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllHistory(currentPage, PER_PAGE));
  }, [dispatch, currentPage]);

  const page = Math.ceil(numberOfHistory / PER_PAGE);

  return (
    <div className={`w-[100%] h-[100%] ${open ? "overflow-hidden" : ""}`}>
      {open && <Layout open={open} setOpen={setOpen} />}
      <CreateStudent open={open} setOpen={setOpen} />
      <div className="w-[100%] h-[10%] flex justify-between items-center">
        <div className="flex items-center w-[50%]">
          <p
            className="pl-2
               border-l-[1px] border-[#d6dce1] dark:text-white  text-[#4b4b4b] text-[1.714rem] tracking-[1.2]"
          >
            كشف حساب
          </p>
          <p className="mr-3 dark:text-white">الطلبات</p>
        </div>
        {/* <div className="flex items-center w-[50%]">
          <div className="flex flex-1">
            <div className="w-[87%]">
              <input
                placeholder="ابحث عن طالب ..."
                className="w-[100%] text-[1rem] text-[#6e6b7b] font-[400] tracking-[1.45] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] border-l-0 rounded-[0.357rem] rounded-l-none outline-none"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className=" bg-[#fff] p-[9px] border-[1px] border-[#d8d6de]  rounded-[0.357rem] rounded-r-none border-r-0">
              <div className="union" onClick={HandlerSearch}>
                <IoIosSearch
                  className="cursor-pointer text-[#6e6b7b]"
                  size={20}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="text-[1em] w-[159px] flex items-center bg-[#7367f0] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] border-none outline-none rounded-[0.357rem] text-[#fff]"
            >
              إضافة مستخدم
              <div className="union mr-3">
                <FaPlus size={15} />
              </div>
            </button>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center w-[100%] mt-4">
        <div className="w-[100%]">
          <table className="w-full bg-white border dark:bg-slate-800 border-gray-300">
            <thead>
              <tr className="text-slate-400 dark:text-white text-sm text-center">
                <th className="py-2 px-4 border-b">اسم الطالب</th>
                <th className="py-2 px-4 border-b">رقم الطالب</th>
                <th className="py-2 px-4 border-b">اسم الاستاذ</th>
                <th className="py-2 px-4 border-b">رقم الاستاذ</th>
              </tr>
            </thead>
            <tbody>
              {history?.map((history) => (
                <tr key={history} className="text-center dark:text-white">
                  <td className="py-4 px-6 border-b">
                    {history?.studentUserName}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {history?.studentPhoneNumber}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {history?.teacherUserName}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {history?.teacherPhoneNumber}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        pages={page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
