import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginAdmin } from "../redux/apiCalls/AuthApiCall";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import { getIdCookie } from "../utils/cookies";

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.admin.loading);

  const OnHandlerSubmit = (e) => {
    e.preventDefault();
    const userData = {
      phoneNumber,
      password,
    };
    dispatch(LoginAdmin(userData, setState));
  };

  useEffect(() => {
    if (state) {
      toast.success(`Welcome ${getIdCookie()?.userName}`);
      navigate("/dashboard/main", { replace: true });
    }
  }, [state, navigate]);
  return (
    <div className="xl:w-[100%] h-[100vh] relative overflow-hidden flex">
      <div className="w-[100%] h-[4%] absolute left-0 top-0">
        <p className="w-[100%] text-start p-7 pr-8 bg-clip-text text-transparent bg-gradient-to-r from-white to-[#7367f0] text-2xl font-almarai font-bold">
          FYT Project
        </p>
      </div>
      <div className="block max-xl:w-[100%]   w-[66.65%] h-[100%] overflow-hidden  bg-[#f6f6f6]">
        <div className="w-[100%] h-[100%] overflow-hidden">
          <div className="w-[100%] h-[96%] flex justify-center items-center p-4 ">
            <img
              src="/assests/FYT Logo-08.png"
              alt="Logo_FYT"
              className="w-[75.8%] mb-[29px]"
            />
          </div>
        </div>
      </div>
      <div className="w-[100%] xl:block hidden  xl:w-[33.35%] h-[100%] bg-white xl:mx-0 md:mx-20">
        <div className="w-[100%] h-[100%] flex flex-col items-start justify-center p-16 space-y-3">
          <h3 className="text-[24px]  font-light ltr">
          مرحبا" بكم في !FYT Project 👋
          </h3>
          <p className="text-sm  font-light">
            رجاء سجل الدخول بحسابك لتبدأ الإستكشاف
          </p>
          <div className="w-[100%]">
            <form className="mt-5 w-[100%]">
              <div className="flex flex-col items-start w-[100%]">
                <label className="text-sm font-light">رقم المستخدم</label>
                <input
                  className="border border-1 outline-none rounded-sm text-sm border-[#b8c2cc] text-start w-[100%] mt-1 p-2"
                  type="phoneNumber"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="flex flex-col mt-4 items-start w-[100%]">
                <label className="text-sm  font-light">كلمة المرور</label>
                <input
                  className="border border-1 outline-none rounded-sm text-sm border-[#b8c2cc] text-start w-[100%] mt-1 p-2"
                  type="password"
                  placeholder="Password"
                  value={password}
                  style={{ textAlign: 'right' }}
                  onChange={(e) => setPassword(e.target.value)}
                  dir="rtl"
                />
              </div>
              <div className="flex mt-4 items-center justify-start w-[100%]">
                <label className="text-sm  font-light">تذكرني</label>
                <input
                  className="border border-1 bg-[#7367f0] outline-none rounded-sm border-[#b8c2cc] mr-2 mt-1 p-2"
                  type="checkBox"
                  placeholder="Password"
                />
              </div>
              <div
                className="flex mt-4 justify-center items-center p-2 cursor-pointer rounded-md bg-[#7367f0]"
                onClick={OnHandlerSubmit}
              >
                <button className="text-white  font-light">
                  {loading ? <Loading /> : "تسجبل الدخول"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
}
