import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { historyAction } from "../slices/historySlice";

export function getAllHistory(currentPage, PER_PAGE) {
  return async (dispatch) => {
    dispatch(historyAction.setLoading());
    try {
      const queryParameters = new URLSearchParams();
      if (currentPage) queryParameters?.append("page", currentPage);
      if (PER_PAGE) queryParameters?.append("perPage", PER_PAGE);
      const { data } = await request.get(
        `/history?${queryParameters.toString()}`
      );
      console.log(data);
      dispatch(historyAction.setHistory(data.history)); // Corrected payload
      dispatch(historyAction.setNumberOfHistory(data?.documentCount));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(historyAction.setLoading());
    }
  };
}
