import { createSlice } from "@reduxjs/toolkit";

const historySlice = createSlice({
  name: "history",
  initialState: {
    history: [],
    numberOfHistory: 0,
    loading: false,
  },
  reducers: {
    setHistory(state, action) {
      state.history = action.payload;
    },
    setNumberOfHistory(state, action) {
      state.numberOfHistory = action.payload;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
  },
});

const historyReducer = historySlice.reducer;
const historyAction = historySlice.actions;

export { historyAction, historyReducer };
