import React,{useState} from 'react'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import { Outlet } from "react-router-dom";
export default function Dashboard() {

  const [navBarWidth, setNavBarWidth] = useState(false)

  
  return (
    <div className=' bg-[#f6f6f6] dark:bg-slate-900 h-[100vh] w-[100%] flex'>
      <div className={`${navBarWidth ? "w-[20%]" : "w-[3.5%]"} h-[100%] ease duration-200`}>
        <Sidebar navBarWidth={navBarWidth} setNavBarWidth={setNavBarWidth}/>
      </div>

      <div className={`${navBarWidth ? "h-[100%]" : "w-[96%]"} h-[100%] flex-1`}>
        <div className='w-[100%] h-[15%]'>
          <Header />

        </div>
        <div className='w-[100%] overflow-hidden bg-[#f6f6f6] dark:bg-slate-900 '>
          <div className='w-[100%] h-[100%] px-6 bg-[#f6f6f6] dark:bg-slate-900 '>
            <Outlet />
          </div>
        </div>
      </div>

    </div>
  )
}
