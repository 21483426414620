import { createSlice } from "@reduxjs/toolkit";

const subjectSlice = createSlice({
  name: "subject",
  initialState: {
    subjects: [],
    numberOfSubjects: 0,
    isOpenedSubjects: false,
    loading: false,
    searchTeacher: 0,
    subject: null
  },
  reducers: {
    setSubject(state, action) {
      state.subjects = action.payload;
    },
    setNumberOfSubjects(state, action) {
      state.numberOfSubjects = action.payload;
    },
    removeSubject(state, action) {
      const noti = action.payload;
      state.subjects = state.subjects.filter(
        (subject) => subject.id !== noti
      );
    },
    setIsOpenedSubject(state) {
      state.isOpenedSubjects = !state.isOpenedSubjects;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
    addSubject(state, action) {
      state.subjects.push(action.payload)
    },
    searchTeach(state, action){
      state.searchTeacher = action.payload
    },
    getSubjectdata(state, action){
      state.subject = action.payload
    }
  },
});

const subjectsReducer = subjectSlice.reducer;
const subjectsAction = subjectSlice.actions;

export { subjectsAction, subjectsReducer };
