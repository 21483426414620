import { toast } from "react-toastify";
import {request} from "../../utils/request";
import { notificationsAction } from "../slices/notificationsSlice";

const PER_PAGE = 4;

export function getNotifications(currentPage) {
  return async (dispatch) => {
      dispatch(notificationsAction.setLoading())
      try {
        const { data } = await request.get(
          `/notifications/for-dash?page=${currentPage}&perPage=${PER_PAGE}`
        );
        dispatch(notificationsAction.setNotifications(data?.notifications));
        dispatch(notificationsAction.setNumberOfNotifications(data?.documentCount));
      } catch (error) {
          // toast.error(error.response.data.message);
        console.log(error);
      }finally{
        
        dispatch(notificationsAction.setLoading())
      }

    };
}

export function deleteNotifications(notificationId, currentPage, setCurrentPage) {
  return async (dispatch) => {
    try {
      if (Array.isArray(notificationId)) {
        await Promise.all(notificationId.map(async (e) => {
          await request.delete(`/notifications/${e}`);
          dispatch(notificationsAction.removeNotifications(e));
        }));
      }else {
        await request.delete(`/notifications/${notificationId}`);
        dispatch(notificationsAction.removeNotifications(notificationId));
      }
      toast.success("تم حذف الرسائل...");
    } catch (error) {
      console.log(error);
    } finally {
      let x = currentPage;
      if (currentPage !== 1) x = currentPage - 1;
      setCurrentPage(x);
      dispatch(getNotifications(x));
    }
  };
}
  


export function createNotification(
  message,
  setCurrentPage,
  setAllEmpty
) {
  return async (dispatch) => {
    try {
      console.log(message);
      dispatch(notificationsAction.setLoading());
      await request.post(`/notifications`, message, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success("تم ارسال الاشعار...");
      setAllEmpty()
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
    } finally {
      dispatch(getNotifications(1))
      setCurrentPage(1)
      dispatch(notificationsAction.setLoading());
    }
  };
}
