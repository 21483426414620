import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createNotification } from '../redux/apiCalls/notificationsApiCall';

const SendNotification = ({ open, setOpen, setCurrentPage }) => {
  const [recipient, setRecipient] = useState('');
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const dispatch = useDispatch()

  const setAllEmpty = () => {
        setRecipient("")
        setTitle("")
        setBody("")
  }

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };

  const sendNotification = () =>{
    if(title.trim === "")toast.error("the title of message is requierd")
    if(body.trim === "")toast.error("the body of message is requierd")
    if(recipient.trim === "")toast.error("choose the recipient")
    
    dispatch(createNotification({title, body, allUsers:recipient}, setCurrentPage, setAllEmpty))
  }
  
  return (
    
    <div className={`transition-all ease duration-[0.4s] ${open ? 'right-0' : 'right-[-320px]'} absolute w-[320px] bg-[#f6f6f6] top-0 h-[100%] z-10 flex flex-col justify-between`}>
      <div>
      <div className='flex items-center justify-between pr-[12px] pl-[12px] pt-[6px] pb-[10px] border-b-[1px] border-[#ebe9f1]'>
        <p>إرسال إشعار</p>
        <div className="union">
          <IoMdClose className='cursor-pointer' onClick={() => {
            setOpen(false);
          }} />
        </div>
      </div>

      <div className='p-[12px] text-[#5e5873] mb-[.2857rem] text-[.857rem]'>
        <p className='mb-[5px]'>ارسال الى</p>
        <div className='flex items-center mr-[5px] mb-[3px]' >
          <input type="radio" id='student' value="students" checked={recipient === 'students'} onChange={handleRecipientChange} className='ml-[4px] cursor-pointer' />
          <label htmlFor="student" className='cursor-pointer'>الطلاب</label>
        </div>
        <div className='flex items-center mr-[5px] mb-[3px]' >
          <input type="radio" id='teacher' value="teachers" checked={recipient === 'teachers'} onChange={handleRecipientChange} className='ml-[4px] cursor-pointer' />
          <label htmlFor="teacher" className='cursor-pointer'>الأساتذة</label>
        </div>
        <div className='flex items-center mr-[5px] mb-[3px]' >
          <input type="radio" id='all' value="ALL" checked={recipient === 'ALL'} onChange={handleRecipientChange} className='ml-[4px] cursor-pointer' />
          <label htmlFor="all" className='cursor-pointer'>الجميع</label>
        </div>
      </div>

      <div className='pl-[12px] pr-[12px]'>
          <p className='text-[#5e5873] mb-[.2857rem] text-[.857rem]  '>عنوان الإشعار</p>
          <input value={title} onChange={(e) => {setTitle(e.target.value)}} type="text" className='w-[100%] h-[2.714rem] text-[1rem] font-[400] tracking-[400] text-[#6e6b7b]
          pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] rounded-[0.357rem] outline-none mb-[10px]' placeholder='أدخل العنوان'/>
      </div>

      <div className='pl-[12px] pr-[12px] '>
          <p className='text-[#5e5873] mb-[.2857rem] text-[.857rem]'>نص الإشعار</p>
          <textarea value={body} onChange={(e) => {setBody(e.target.value)}} className='w-[100%] text-[1rem] font-[400] tracking-[400] text-[#6e6b7b]
          pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] rounded-[0.357rem] outline-none resize-none h-[120px]' placeholder='أدخل النص'></textarea>
      </div>
      </div>

      <div className='flex items-center justify-around pl-[12px] pr-[12px] pb-[12px]'>
        <button className='pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center transition-[0.3s] hover:bg-[#82868b06]' onClick={() => {
          setOpen(false)
        }}>إلغاء</button>
        <button onClick={sendNotification} className='send-btn pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center bg-[#7367f0] transition-[0.3s]'>ارسال</button>
      </div>


    </div>
  );
};

export default SendNotification;
