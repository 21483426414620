import React, { useState } from "react";

const SubjectSelect = ({
  subjects,
  setSelectedSubject,
  locations,
  setSelectedLocation,
  acadimics,
  setSelectAcadmic,
  setSelectGender,
  setSelectMaxPrice,
  setSelectMinPrice,
  setSelectActive,
  setSelectPhoneNumber,
}) => {
  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleAcadimicChange = (e) => {
    setSelectAcadmic(e.target.value);
  };

  const handleGenderChange = (e) => {
    setSelectGender(e.target.value);
  };

  const handleMinPriceChange = (e) => {
    setSelectMinPrice(e.target.value);
  };

  const handleMaxPriceChange = (e) => {
    setSelectMaxPrice(e.target.value);
  };

  const handleActiveChange = (e) => {
    setSelectActive(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setSelectPhoneNumber(e.target.value);
  };

  const genders = [
    { _id: 1, name: "ذكر", value: 'male'},
    { _id: 2, name: "انثى", value: 'female'},
  ];

  const actives = [
    { _id: 1, name: "مفعل", value: "true" },
    { _id: 2, name: "غير مفعل", value: "false" },
  ];

  return (
    <>
      <div className="flex w-[100%]">
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">المواد</p>

          <select
            id="subjects"
            onChange={handleSubjectChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          >
            <option value="">اختر مادة</option>
            {subjects?.map((subject) => (
              <option key={subject._id} value={subject._id}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">المواقع</p>

          <select
            id="location"
            onChange={handleLocationChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          >
            <option value="">اختر موقع</option>
            {locations?.map((location) => (
              <option key={location?._id} value={location?._id}>
                {location?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">المستوى التعليمي</p>

          <select
            id="location"
            onChange={handleAcadimicChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          >
            <option value="">اختر المستوى</option>
            {acadimics?.map((acadimic) => (
              <option key={acadimic?._id} value={acadimic?._id}>
                {acadimic?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">الجنس:</p>

          <select
            id="location"
            onChange={handleGenderChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          >
            <option value="">اختر الجنس</option>
            {genders?.map((gender) => (
              <option key={gender?._id} value={gender?.value}>
                {gender?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">النشاط:</p>

          <select
            id="location"
            onChange={handleActiveChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          >
            <option value="">اختر النشاط</option>
            {actives?.map((active) => (
              <option key={active?._id} value={active?.value}>
                {active?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">السعر الأدنى:</p>
          <input
            type="number"
            onChange={handleMinPriceChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          />
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">السعر الأقصى:</p>
          <input
            type="number"
            onChange={handleMaxPriceChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          />
        </div>
        <div className="w-[100%]">
          <p className="text-slate-500 text-sm font-light p-2">رقم الهاتف:</p>
          <input
            type="text"
            onChange={handlePhoneNumberChange}
            className="p-2 text-slate-500 bg-white border border-2 w-[90%] rounded-md"
          />
        </div>
      </div>
    </>
  );
};

export default SubjectSelect;
