import React, { useEffect, useState } from 'react'
import { IoIosSearch } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { GoTrash } from "react-icons/go";
import Notification from '../Components/Notification';
import { Pagination } from '../Components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotifications, getNotifications } from '../redux/apiCalls/notificationsApiCall';
import Layout from '../Components/Layout';
import SendNotification from '../Components/SendNotification';

const PER_PAGE = 4;
const Notifications = () => {
    const [selectedChats, setSelectedChats] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const {notifications,numberOfNotifications} = useSelector(state => state.notifications)
    const [currentPage, setCurrentPage] = useState(1)
    const [open, setOpen] = useState(false);
    
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getNotifications(currentPage))
    },[currentPage, dispatch])
    const pages = Math.ceil(numberOfNotifications / PER_PAGE);
    
   

    const handleCheckboxChange = (chatId) => {
        if (selectedChats.includes(chatId)) {
          setSelectedChats((prevSelectedChats) =>
            prevSelectedChats.filter((id) => id !== chatId)
          );
        } else {
          setSelectedChats((prevSelectedChats) => [...prevSelectedChats, chatId]);
        }
      };
    
      const handleSelectAllChats = () => {
        if (selectAll) {
          setSelectedChats([]);
        } else {
          const allChatIds = notifications?.map((chat) => chat.id);
          setSelectedChats(allChatIds);
        }
        setSelectAll((prevSelectAll) => !prevSelectAll);
      };

  return (
    <div>
        
        {open && <Layout open={open} setOpen={setOpen}/>}
        <SendNotification open={open} setOpen={setOpen} setCurrentPage={setCurrentPage}/>
        <div className='notifications-header flex mb-[12px]'>
            <div className='flex items-center w-[50%]'>
                <p className='pl-2
                 border-l-[1px] border-[#d6dce1]  text-[#4b4b4b] text-[1.714rem] tracking-[1.2]'>الإشعارات</p>
                <p className='mr-3'>الإشعارات</p>
            </div>
            <div className='flex items-center w-[50%]'>
                <div className="flex flex-1">
                    <div className='w-[87%]'>
                        <input placeholder='ابحث عن إشعار ما...' className='w-[100%] text-[1rem] text-[#6e6b7b] font-[400] tracking-[1.45] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] border-l-0 rounded-[0.357rem] rounded-l-none outline-none' type="text" />
                    </div>
                    <div className=' bg-[#fff] p-[9px] border-[1px] border-[#d8d6de]  rounded-[0.357rem] rounded-r-none border-r-0'>
                        <div className="union">
                            <IoIosSearch className='cursor-pointer text-[#6e6b7b]'  size={20}/>
                        </div>
                    </div>
                </div>

                <button onClick={() => {
                    setOpen(true)
                }} className='text-[.9rem] flex items-center bg-[#7367f0] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] border-none outline-none rounded-[0.357rem] text-[#fff]' >
                    ارسال الإشعار
                    <div className="union mr-3" >
                        <FaPlus size={15} />
                    </div>
                </button>
            </div>
        </div> 

        <div className='shadow-md flex items-center justify-between pl-[12px] pr-[12px] pt-[5px] pb-[5px] bg-[#fff] rounded-[8px] mb-[12px]'>
            <p className='cursor-pointer font-[400] text-[.857rem] text-[#6e6b7b]' onClick={handleSelectAllChats}>تحديد الكل</p>
            <div className="union pt-[0.5rem] pb-[0.5rem] pl-[10px] pr-[10px] cursor-pointer">
                <GoTrash onClick={() =>{
                    dispatch(deleteNotifications(selectedChats, currentPage, setCurrentPage))
                    setSelectedChats([])
                }}/>
            </div>
        </div>

        <div className="notifications-body">
            {notifications?.map((notification, idx) => (
                <Notification
                 key={idx}
                  notification={notification}
                    isChecked={selectedChats?.includes(notification.id)}
                    handleCheckboxChange={handleCheckboxChange}
                 />     
            ))}
        </div>

        <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </div>
  )
}

export default Notifications