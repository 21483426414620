import React, { useState } from 'react'
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addStudent } from '../redux/apiCalls/StudentsApiCalls';
import { toast } from "react-toastify";

export default function CreateStudent({ open, setOpen }) {
    
    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('student');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const handlerAddStudent = (e) => {
        e.preventDefault();
    
        if (!userName || !phoneNumber || !password) {
            return toast.error('complete your data');
        }
    
        const data = {
            userName,
            phoneNumber,
            password,
            role
        }
    
        dispatch(addStudent(data, setOpen));
    }
  return (
    <div>
    <div className={`transition-all  ease duration-[0.4s] ${open ? 'right-0' : 'right-[-320px]'} absolute w-[320px] bg-[#f6f6f6] top-0 h-[100%] z-20`}>
        <div className='flex items-center justify-between pr-[12px] pl-[12px] pt-[6px] pb-[10px] border-b-[1px] border-[#ebe9f1]'>
            <p>إضافة طالب</p>
            <div className="union">
                <IoMdClose className='cursor-pointer' onClick={() => {
                    setOpen(false);
                }} />
            </div>
        </div>
        <div className="max-w-md mx-auto h-[94%] rounded-md shadow-md">
            <form 
                onSubmit={handlerAddStudent} 
                className="flex h-[100%]  flex-col justify-between py-8  mx-4"
            >
                <div>
                <div className="mb-4">
                    <label htmlFor="userName" className="block text-gray-700 font-bold mb-2">الإسم :</label>
                    <input type="text" id="userName" value={userName} onChange={(e) => setUserName(e.target.value)} name="userName" placeholder="Enter username" className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
                <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block text-gray-700 font-bold mb-2">رقم الهاتف :</label>
                    <input type="tel" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} name="phoneNumber" placeholder="Enter phone number" className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700 font-bold mb-2">كلمة السر :</label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" placeholder="Enter password" className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
                </div>

                <div className="flex items-center justify-around w-[100%] pl-[12px] pr-[12px] pb-[12px]">
                    <button
                        className="pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center transition-[0.3s] hover:bg-[#82868b06]"
                        onClick={() => {
                        setOpen(false);
                        }}
                    >
                        إلغاء
                    </button>
                    <button
                        onClick={handlerAddStudent}
                        className="send-btn pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center bg-[#7367f0] transition-[0.3s]"
                    >
                        إضافة
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
  )
}
