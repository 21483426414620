import { request } from "../../utils/request";
import { toast } from "react-toastify";
import { dataAction } from "../slices/authSlice";
import { setIdCookie } from "../../utils/cookies";

export const LoginAdmin = (userData, setState) => {
  return async (dispatch) => {
    dispatch(dataAction.setLoading());
    try {
      const { data } = await request.post("/auth/login_admin", userData);
      setIdCookie(data?.user);
      console.log(data);
      setState(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.message);
    } finally {
      dispatch(dataAction.setLoading());
    }
  };
};
