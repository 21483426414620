import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { GoTrash } from "react-icons/go";
import {
  activeOfTeacher,
  EditTeacher,
  getAllTeachers,
  ProfileTeacher,
  removeTeacher,
} from "../redux/apiCalls/TeachersApiCalls";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Components/Layout";
import CreateTeacher from "../Components/CreateTeacher";
import { Pagination } from "../Components/Pagination";
import SubjectSelect from "../Components/Select";
import { getAllSubjects } from "../redux/apiCalls/SubjectApiCall";
import { getAllLocations } from "../redux/apiCalls/LocationApiCalls";
import { getAllAcadimic } from "../redux/apiCalls/AcadimicApiCall";
import { FaUserEdit } from "react-icons/fa";
import EditTeach from '../Components/EditTeach'
 
export default function Teachers() {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [PER_PAGE, setPER_PAGE] = useState(14);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectAcadimic, setSelectAcadmic] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [selectMinPrice, setSelectMinPrice] = useState("");
  const [selectMaxPrice, setSelectMaxPrice] = useState("");
  const [selectActive, setSelectActive] = useState("");
  const [selectPhoneNumber, setSelectPhoneNumber] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState(false);
  const { teachers, numberOfTeachers } = useSelector((state) => state.teacher);
  const subjects = useSelector((state) => state.subject.subjects);
  const locations = useSelector((state) => state.location.locations);
  const acadimics = useSelector((state) => state.acadimic.acadimics);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.teacher.loading);


  const HandlerSearch = () => {
    setCurrentPage(1);
  };

  const reTeacgers = () => {
    dispatch(
      getAllTeachers(
        currentPage,
        PER_PAGE,
        selectedSubject,
        selectedLocation,
        selectAcadimic,
        selectGender,
        selectActive,
        selectPhoneNumber,
        selectMinPrice,
        selectMaxPrice,
        filter,
        searchValue
      )
    )
  }

  const handlerProfile = (id) => {
    setEdit(true)
    console.log({id:id})
    dispatch(ProfileTeacher(id))
  }

  useEffect(() => {
    dispatch(
      getAllTeachers(
        currentPage,
        PER_PAGE,
        selectedSubject,
        selectedLocation,
        selectAcadimic,
        selectGender,
        selectActive,
        selectPhoneNumber,
        selectMinPrice,
        selectMaxPrice,
        filter,
        searchValue
      )
    );
  }, [
    dispatch,
    currentPage,
    selectedSubject,
    selectedLocation,
    selectAcadimic,
    selectGender,
    selectMinPrice,
    selectActive,
    selectPhoneNumber,
    selectMaxPrice,
    filter,
    searchValue,
    PER_PAGE,
  ]);


  useEffect(() => {
    dispatch(getAllSubjects());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllLocations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAcadimic());
  }, [dispatch]);
  const page = Math.ceil(numberOfTeachers / PER_PAGE);

  return (
    <div
      className={`w-[100%] h-[100%] bg-[#f6f6f6] dark:bg-slate-900 ${
        open || edit ? "overflow-hidden h-[85vh]" : ""
      }`}
    >
      
      {open && <Layout open={open} setOpen={setOpen} />}
      <CreateTeacher open={open} setOpen={setOpen} />
      {edit && <Layout open={edit} setOpen={setEdit} />}
      <EditTeach open={edit} setOpen={setEdit}  reTeacgers={reTeacgers}/>

      <div className="w-[100%] h-[10%] flex justify-between items-center">
        <div className="flex items-center w-[50%]">
          <p
            className="pl-2
                 border-l-[1px] border-[#d6dce1] dark:text-white text-[#4b4b4b] text-[1.714rem] tracking-[1.2]"
          >
            الاساتذة
          </p>
          <p className="mr-3 dark:text-white">الاساتذة</p>
        </div>
        <div className="flex items-center w-[50%]">
          <div className="flex flex-1">
            <div className="w-[87%]">
              <input
                placeholder="ابحث عن إستاذ ..."
                className="w-[100%] text-[1rem] text-[#6e6b7b] font-[400] tracking-[1.45] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] border-l-0 rounded-[0.357rem] rounded-l-none outline-none"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className=" bg-[#fff] p-[9px] border-[1px] border-[#d8d6de]  rounded-[0.357rem] rounded-r-none border-r-0">
              <div className="union" onClick={HandlerSearch}>
                <IoIosSearch
                  className="cursor-pointer text-[#6e6b7b]"
                  size={20}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="text-[1em] w-[159px] flex items-center bg-[#7367f0] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] border-none outline-none rounded-[0.357rem] text-[#fff]"
            >
              إضافة مستخدم
              <div className="union mr-3">
                <FaPlus size={15} />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-[100%] flex-col">
        <div className="w-[100%] my-4 flex flex-col bg-white dark:bg-slate-800 p-[20px] ">
          <div className="flex w-[100%] justify-between items-center ">
            <p className="mb-4 dark:text-white">فلترة حسب</p>
            <div className="flex gap-4 pl-[12px] pr-[12px] pb-[12px]">
              <button
                onClick={() => setFilter(true)}
                className={`send-btn ${
                  filter ? "bg-[#7367f0]  text-white" : "bg-white dark:bg-slate-900 dark:text-white text-black"
                } pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center transition-[0.3s]`}
              >
                فلترة
              </button>
              <button
                className={`pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] ${
                  filter ? "bg-white dark:bg-slate-900 dark:text-white text-black" : "bg-[#7367f0]  text-white"
                } rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center `}
                onClick={() => {
                  setFilter(false);
                }}
              >
                إلغاء
              </button>
            </div>
          </div>

          <SubjectSelect
            subjects={subjects}
            selectedSubject={selectedSubject}
            setSelectedSubject={setSelectedSubject}
            locations={locations}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            acadimics={acadimics}
            selectAcadimic={selectAcadimic}
            setSelectAcadmic={setSelectAcadmic}
            selectGender={selectGender}
            setSelectGender={setSelectGender}
            selectMaxPrice={selectMaxPrice}
            setSelectMaxPrice={setSelectMaxPrice}
            selectMinPrice={selectMinPrice}
            setSelectMinPrice={setSelectMinPrice}
            selectActive={selectActive}
            setSelectActive={setSelectActive}
            selectPhoneNumber={selectPhoneNumber}
            setSelectPhoneNumber={setSelectPhoneNumber}
          />
        </div>

        <div className="w-[100%]">
          <table className="w-full bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-600">
            <thead>
              <tr className="text-slate-400 dark:text-white text-sm text-center">
                <th className="py-2 px-4 border-b">اسم المستخدم</th>
                <th className="py-2 px-4 border-b">رقم المستخدم</th>
                <th className="py-2 px-4 border-b">الموقع</th>
                <th className="py-2 px-4 border-b">المواد</th>
                <th className="py-2 px-4 border-b">المستوى الأكاديمي</th>
                <th className="py-2 px-4 border-b">الطلب</th>
                <th className="py-2 px-4 border-b">سعر</th>
                <th className="py-2 px-4 border-b">العمر</th>
                <th className="py-2 px-4 w-[97px] border-b">نشط</th>
                <th className="py-2 px-4 border-b">...</th>
              </tr>
            </thead>
            <tbody>
              {teachers?.map((teacher, index) => {
                // Calculate age based on birthdate
                function calculateAge(birthdate) {
                  if (!birthdate) {
                    return "Birthdate not provided";
                  }

                  const today = new Date();
                  let birthDate = new Date(birthdate);

                  // Check if birthDate is a valid Date object
                  if (isNaN(birthDate?.getTime())) {
                    const [day, month, year] = birthdate.split(/[\/\-\.]/); // Split by common date separators
                    birthDate = new Date(`${year}-${month}-${day}`);
                  }

                  let age = today.getFullYear() - birthDate?.getFullYear();
                  const month = today.getMonth() - birthDate?.getMonth();

                  if (
                    month < 0 ||
                    (month === 0 && today.getDate() < birthDate?.getDate())
                  ) {
                    age--;
                  }

                  return age;
                }

                return (
                  <tr key={teacher?.id} className="text-center dark:text-white">
                    <td className="py-4 px-6 border-b">{teacher?.userName}</td>
                    <td className="py-2 px-4 border-b capitalize">
                      {teacher?.phoneNumber}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {teacher.locationsName?.map((location, i) => (
                        <span
                          key={i}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1"
                        >
                          {location}
                        </span>
                      ))}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {teacher.subjectName?.map((subject, i) => (
                        <span
                          key={i}
                          className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 m-1"
                        >
                          {subject}
                        </span>
                      ))}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {teacher.academicLevelName?.map((level, i) => (
                        <span
                          key={i}
                          className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-green-700 m-1"
                        >
                          {level}
                        </span>
                      ))}
                    </td>
                    <td className="py-2 px-4 border-b">{teacher?.count}</td>
                    <td className="py-2 px-4 border-b">{teacher?.price}</td>
                    <td className="py-2 px-4 border-b">
                      {calculateAge(teacher?.birthday)}
                    </td>
                    <td
                      className="py-2 px-4 border-b cursor-pointer"
                      onClick={() =>
                        dispatch(activeOfTeacher(teacher?.id)).then(() =>
                        reTeacgers()
                      )
                      }
                    >
                      <p className=" bg-purple-500 text-white rounded-xl p-2">
                        {teacher?.isOk ? "Ok" : "not Ok"}
                      </p>
                    </td>

                    {/* Display age */}
                    <td className="py-2 px-4 border-b">
                      <div className="flex justify-between items-center">
                        <GoTrash
                          className="mx-auto text-red-400 cursor-pointer"
                          onClick={() =>
                            dispatch(removeTeacher(teacher?.id)).then(() =>
                            reTeacgers()
                            )
                          }
                        />
                        <FaUserEdit className="mx-auto text-blue-400 cursor-pointer mr-1" 
                        onClick={() => {
                          handlerProfile(teacher?.id)
                        }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        pages={page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
