import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addTeacher, EditTeacher, ProfileTeacher } from "../redux/apiCalls/TeachersApiCalls";
import { toast } from "react-toastify";
import Select from "react-select";

export default function EditTeach({open, setOpen, reTeacgers}) {
  const teacher = useSelector((state) => state.teacher.teacher);
  
  console.log({teacher: teacher})

  const subjectTeacher = teacher?.subjects
  const subjectNameTeacher = teacher?.subjectName

  const subjectsOptions = subjectTeacher?.map((id, index) => ({
    value: id,
    label: subjectNameTeacher[index]
  }));

  const locationTeacher = teacher?.locations
  const locationNameTeacher = teacher?.locationsName

  const locationsOptions = locationTeacher?.map((id, index) => ({
    value: id,
    label: locationNameTeacher[index]
  }));

  const acadimicTeacher = teacher?.academicLevel
  const acadimicNameTeacher = teacher?.academicLevelName

  const acadimicsOptions = acadimicTeacher?.map((id, index) => ({
    value: id,
    label: acadimicNameTeacher[index]
  }));

  console.log({subjectsOptions: subjectsOptions})
  console.log({locationsOptions: locationsOptions})
  console.log({acadimicsOptions: acadimicsOptions})


  const dispatch = useDispatch();



  const [userName, setUserName] = useState(teacher?.userName || '');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [locationsSelect, setLocationsSelect] = useState([]);
  const [academicLevel, setAcademicLevel] = useState([]);
  const [price, setPrice] = useState(teacher?.price || 0);

  useEffect(() => {
    if (teacher) {
      setUserName(teacher.userName || '');
      setSelectedSubjects(subjectsOptions?.map(subject => ({ value: subject.value, label: subject.label })) );
      setLocationsSelect(locationsOptions?.map(location => ({ value: location.value, label: location.label })));
      setAcademicLevel(acadimicsOptions?.map(level => ({ value: level.value, label: level.label })));
      setPrice(teacher.price || 0);
    } 
  }, [teacher]);
  

  const subjects = useSelector((state) => state.subject.subjects);
  const locations = useSelector((state) => state.location.locations);
  const acadimics = useSelector((state) => state.acadimic.acadimics);

  const handlerAddTeacher = (e) => {
    e.preventDefault();

    const infoTeacher = {
      userName: userName,
      subjects: selectedSubjects?.map(subject => subject?.value),
      locations: locationsSelect?.map(location => location?.value),
      academicLevel: academicLevel?.map(acadimic => acadimic?.value),
      price: price,
    };

    dispatch(EditTeacher(teacher?.id, infoTeacher)).then(() =>
    reTeacgers()
    )

    setUserName()
    setSelectedSubjects([])
    setLocationsSelect([])
    setAcademicLevel([])
    setPrice()
    setOpen(false)
  };

  const optionsSubjects = subjects?.map(subject => ({
    value: subject?.id,
    label: subject?.name,
  }));
  
  const optionsLessons = locations?.map(location => ({
    value: location?.id,
    label: location?.name,
  }));
  
  const optionsAcadimics = acadimics?.map(academic => ({
    value: academic?.id,
    label: academic?.name,
  }));
  
  const genders = [
    { _id: 1, name: "male" },
    { _id: 2, name: "female" }
  ];

  return (
    <div>
      <div
        className={`transition-all  ease duration-[0.4s] ${
          open ? "right-0" : "right-[-720px]"
        } absolute w-[720px] bg-[#f6f6f6] top-0 h-[100%] z-20`}
      >
        <div className="flex items-center w-[100%] justify-between pr-[12px] pl-[12px] pt-[6px] pb-[10px] border-b-[1px] border-[#ebe9f1]">
          <p>تعديل استاذ</p>
          <div className="union">
            <IoMdClose
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="w-[100%] mx-auto   h-[94%] rounded-md shadow-md">
          <form
            onSubmit={handlerAddTeacher}
            className="flex h-[100%]  flex-col justify-between items-strat mx-4"
          >
            <div>
              <div className="mb-4">
                <label
                  htmlFor="userName"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  الإسم :
                </label>
                <input
                  type="text"
                  id="userName"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  name="userName"
                  placeholder="Enter username"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  السعر :
                </label>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subjects"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  اختر المواد :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOptions) =>
                    setSelectedSubjects(selectedOptions)
                  }
                  value={selectedSubjects}
                  options={optionsSubjects}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="locations"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  اختر الموقع :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOptions) =>
                    setLocationsSelect(selectedOptions)
                  }
                  value={locationsSelect}
                  options={optionsLessons}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="acadimics"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  المستوى التعليمي :
                </label>
                <Select
                  className="text-sm"
                  isMulti
                  onChange={(selectedOption) =>
                    setAcademicLevel(selectedOption)
                  }
                  value={academicLevel}
                  options={optionsAcadimics}
                />
              </div>
            </div>
            <div className="flex items-center justify-around w-[100%] pl-[12px] pr-[12px] pb-[12px]">
              <button
                className="pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center transition-[0.3s] hover:bg-[#82868b06]"
                onClick={() => {
                  setOpen(false);
                }}
              >
                إلغاء
              </button>
              <button
                onClick={handlerAddTeacher}
                className="send-btn pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center bg-[#7367f0] transition-[0.3s]"
              >
                تعديل
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
