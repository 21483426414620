import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { studentsAction } from "../slices/students";

export function getAllStudents(currentPage, PER_PAGE, searchValue) {
  return async (dispatch) => {
    dispatch(studentsAction.setLoading());
    try {
      const queryParameters = new URLSearchParams();

      if (currentPage) queryParameters?.append("page", currentPage);
      if (PER_PAGE) queryParameters?.append("perPage", PER_PAGE);
      if (searchValue) queryParameters?.append("userName", searchValue);

      const { data } = await request.get(
        `/users/profile?role=student&${queryParameters.toString()}`
      );
      console.log(data);
      dispatch(studentsAction.setStudents(data?.users)); // Corrected payload
      dispatch(studentsAction.setNumberOfStudent(data?.documentCount));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(studentsAction.setLoading());
    }
  };
}

export function removeStudent(studentId) {
  return async (dispatch) => {
    dispatch(studentsAction.setLoading());
    try {
      await request.delete(`/users/profile/${studentId}`);
      dispatch(studentsAction.removeStudent(studentId)); // Corrected payload
      toast.success("تم حذف المستخدم");
    } catch (error) {
      toast.error(error.response.data?.message);

      console.log(error);
    } finally {
      dispatch(studentsAction.setLoading());
    }
  };
}

export function addStudent(data, setOpen) {
  return async (dispatch) => {
    dispatch(studentsAction.setLoading());
    try {
      await request.post(`/auth/register`, data);
      dispatch(studentsAction.addStudent(data)); // Corrected payload
      toast.success(`تمت اضافة مستخدم`);
      setOpen(false);
    } catch (error) {
      toast.error(error.response.data?.message);

      console.log(error);
    } finally {
      dispatch(studentsAction.setLoading());
    }
  };
}
