import React, { useState, useEffect } from "react";
import { IoIosSearch } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import CreateTeacher from "../Components/CreateTeacher";
import Layout from "../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubject,
  getAllSubjects,
  getSubject,
  searchSubject,
} from "../redux/apiCalls/SubjectApiCall";
import CreateSubject from "../Components/CreateSubject";
import EditSubject from "../Components/EditSubject";

export default function Methods() {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSubjects());
  }, [dispatch]);

  const { subjects } = useSelector((state) => state.subject);

  const handlersubject = (id) => {
    setEdit(true)
    console.log({id:id})
    dispatch(getSubject(id))
  }

  return (
    <div
      className={`w-[100%] h-[100%] bg-[#f6f6f6]  dark:bg-slate-900 ${
        open || edit ? "overflow-hidden h-[85vh]" : ""
      }`}
    >
      {open && <Layout open={open} setOpen={setOpen} />}
      <CreateSubject open={open} setOpen={setOpen} />

      {edit && <Layout open={edit} setOpen={setEdit} />}
      <EditSubject open={edit} setOpen={setEdit} />

      <div className="w-[100%] h-[10%] flex justify-between items-center">
        <div className="flex items-center w-[50%]">
          <p
            className="pl-2
                 border-l-[1px] border-[#d6dce1] dark:text-white text-[#4b4b4b] text-[1.714rem] tracking-[1.2]"
          >
            المواد
          </p>
          <p className="mr-3 dark:text-white">المواد</p>
        </div>
        <div className="flex items-center w-[50%]">
          <div className="flex flex-1">
            <div className="w-[87%]">
              <input
                placeholder="ابحث عن مادة ..."
                className="w-[100%] text-[1rem] text-[#6e6b7b] font-[400] tracking-[1.45] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] bg-[#fff] border-[1px] border-[#d8d6de] border-l-0 rounded-[0.357rem] rounded-l-none outline-none"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div className=" bg-[#fff] p-[9px] border-[1px] border-[#d8d6de]  rounded-[0.357rem] rounded-r-none border-r-0">
              <div className="union">
                <IoIosSearch
                  className="cursor-pointer text-[#6e6b7b]"
                  size={20}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="text-[1em] w-[159px] flex items-center bg-[#7367f0] pt-[0.438rem] pb-[0.438rem] pr-[1rem] pl-[1rem] border-none outline-none rounded-[0.357rem] text-[#fff]"
            >
              إضافة مادة
              <div className="union mr-3">
                <FaPlus size={15} />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="w-[100%] ">
        <div className="w-[100%] p-8 flex flex-wrap gap-4 justify-center">
          {subjects?.map((item, index) => {

            return (
              <div key={index} className="w-[316px]">
                <div className="block bg-white dark:bg-slate-800 rounded-lg shadow-secondary-1 dark:bg-surface-dark">
                  <div className="relative w-[300px] h-[300px] overflow-hidden bg-cover bg-no-repeat  flex justify-center items-center">
                    <img
                      className="rounded-t-lg dark:bg-white "
                      src={item.subjectImage.url}
                      alt={item.name}
                    />
                    <a href="#!">
                      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                    </a>
                  </div>
                  <div className="p-6 text-surface dark:text-white">
                    <h5 className="mb-2 text-md font-medium leading-tight">
                      {item.name}
                    </h5>
                    <p className="mb-2 text-md font-medium leading-tight">
                      عدد الاساتذة : {item.users.length} 
                    </p>
                    <div className="flex justify-between items-center w-[100%]">
                      <button
                        type="button"
                        className="inline-block rounded bg-[#7367f0] text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                        onClick={() => handlersubject(item.id)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="inline-block rounded bg-red-600 text-white px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                        onClick={() => dispatch(deleteSubject(item.id))}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
