import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { createSubject } from '../redux/apiCalls/SubjectApiCall';

export default function CreateSubject({ open, setOpen }) {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();

  const handlerAddSubject = (e) => {
    e.preventDefault();

    if (!name || !image) {
      return toast.error('Please complete your data');
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', image);

    console.log([...formData]);

    dispatch(createSubject(formData));

    // Reset form fields
    setName('');
    setImage(null);
    setOpen(false);
  }

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  }

  return (
    <div>
      <div
        className={`transition-all ease duration-[0.4s] ${
          open ? "right-0" : "right-[-720px]"
        } absolute w-[720px] bg-[#f6f6f6] top-0 h-[100%] z-20`}
      >
        <div className="flex items-center w-[100%] justify-between pr-[12px] pl-[12px] pt-[6px] pb-[10px] border-b-[1px] border-[#ebe9f1]">
          <p>إضافة مادة</p>
          <div className="union">
            <IoMdClose
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="w-[100%] mx-auto h-[94%] rounded-md shadow-md">
          <form
            onSubmit={handlerAddSubject}
            className="flex h-[100%] flex-col justify-between items-start mx-4"
          >
            <div>
              <div className="mb-4">
                <label
                  htmlFor="image"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  الصورة :
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleFileChange}
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-bold text-sm mb-2"
                >
                  الاسم :
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                  className="appearance-none border rounded-md w-full py-2 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="flex items-center justify-around w-[100%] pl-[12px] pr-[12px] pb-[12px]">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#82868b] font-[400] flex items-center justify-center transition-[0.3s] hover:bg-[#82868b06]"
              >
                إلغاء
              </button>
              <button
                type="submit"
                className="send-btn pt-[0.5rem] pb-[0.5rem] pr-[1.5rem] pl-[1.5rem] rounded-[.358rem] border-[1px] border-[#82868b] text-[1rem] tracking-[1] text-[#fff] font-[400] flex items-center justify-center bg-[#7367f0] transition-[0.3s]"
              >
                إضافة
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
