import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { locationsAction } from "../slices/locationSlice";

export function getAllLocations() {
  return async (dispatch) => {

    dispatch(locationsAction.setLoading());
    try {
      const { data } = await request.get(`/locations`);
      dispatch(locationsAction.setLocation(data?.locations));// Corrected payload
      dispatch(locationsAction.setNumberOfLocations(data?.documentCount))
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(locationsAction.setLoading());
    }
  };
}