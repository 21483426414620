import { createSlice } from "@reduxjs/toolkit";


const acadimicSlice = createSlice({
    name: "acadimic",
    initialState: {
      acadimics: [],
      numberOfAcadimics: 0,
      isOpenedAcadimics: false,
      loading: false,
    },
    reducers: {
      setacadimics(state, action) {
        state.acadimics = action.payload;
      },
      setNumberOfAcadimics(state, action) {
        state.numberOfAcadimics = action.payload;
      },
      removeAcadimics(state, action) {
        const noti = action.payload;
        state.acadimics = state.acadimics.filter(
          (acadimic) => acadimic.id !== noti
        );
      },
      setIsOpenedAcadimic(state) {
        state.isOpenedAcadimics = !state.isOpenedAcadimics;
      },
      setLoading(state) {
        state.loading = !state.loading;
      },
      addAcadimic(state, action) {
        state.acadimics.push(action.payload)
      }
    },
  });

const acadimicsReducer = acadimicSlice.reducer;
const acadimicsAction = acadimicSlice.actions;

export { acadimicsAction, acadimicsReducer };
