import { createSlice } from "@reduxjs/toolkit";

const locaitonSlice = createSlice({
  name: "location",
  initialState: {
    locations: [],
    numberOfLocations: 0,
    isOpenedLocations: false,
    loading: false,
  },
  reducers: {
    setLocation(state, action) {
      state.locations = action.payload;
    },
    setNumberOfLocations(state, action) {
      state.numberOfLocations = action.payload;
    },
    removeLocation(state, action) {
      const noti = action.payload;
      state.locations = state.locations.filter(
        (location) => location.id !== noti
      );
    },
    setIsOpenedLocation(state) {
      state.isOpenedLocations = !state.isOpenedLocations;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
    addLocation(state, action) {
      state.locations.push(action.payload)
    }
  },
});

const locationsReducer = locaitonSlice.reducer;
const locationsAction = locaitonSlice.actions;

export { locationsAction, locationsReducer };