import { toast } from "react-toastify";
import { request } from "../../utils/request";
import { acadimicsAction } from "../slices/acadimicSlice";

export function getAllAcadimic() {
  return async (dispatch) => {

    dispatch(acadimicsAction.setLoading());
    try {
      const { data } = await request.get(`/academicLevels`);
      dispatch(acadimicsAction.setacadimics(data?.academicLevels)); // Corrected payload
      dispatch(acadimicsAction.setNumberOfAcadimics(data?.documentCount))
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(acadimicsAction.setLoading());
    }
  };
}