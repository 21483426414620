import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiHome } from "react-icons/fi";
import { PiStudent } from "react-icons/pi";
import { PiChalkboardTeacherLight } from "react-icons/pi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuMessageSquare } from "react-icons/lu";
import { IoQrCodeOutline } from "react-icons/io5";
import { RiStoreLine } from "react-icons/ri";
import { MdFindReplace } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { RiInputMethodFill } from "react-icons/ri";
import { getIdCookie } from "../utils/cookies";

const Sidebar = ({ setNavBarWidth, navBarWidth }) => {
  const [mouseMove, setMouseMove] = useState(false);

  const role = getIdCookie()?.role

  const hanlderChangeWidth = () => {
    setNavBarWidth(!navBarWidth);
  };

  const handlerMouseEnter = () => {
    setMouseMove(true);
  };

  const handlerMouseLeave = () => {
    setMouseMove(false);
  };

  
  

  return (
    <div
      onMouseLeave={handlerMouseLeave}
      onMouseEnter={handlerMouseEnter}
      className={`z-[4] group bg-white dark:bg-slate-800 fixed  h-[100%] ease duration-200  ${
        navBarWidth ? "w-[20%]" : " w-[3.5%] hover:w-[20%]"
      } `}
    >
      <div className="w-[100%] h-[10%] flex justify-between items-center pl-[20px]">
        <div className="flex justify-start items-center w-[100%] ">
          <div className={`${navBarWidth || mouseMove ? "" : ""}`}>
            <img
              src="/assests/FYT Logo-08.png"
              alt=""
              className={` ${
                navBarWidth || mouseMove
                  ? "min-w-[50px] max-w-[50px] w-[50px]"
                  : "min-w-[50px] max-w-[50px] w-[50px]"
              } group-hover:w-[50px]  ease duration-[1s]`}
            />
          </div>

          <div
            className={`${
              navBarWidth || mouseMove
                ? "block opacity-[1]"
                : "hidden opacity-0"
            } group-hover:block `}
          >
            <span
              className={`text-[#7367f0] font-semibold tracking-[0.01rem] text-[1.45rem] pr-[1rem] `}
            >
              FYT
            </span>
          </div>
        </div>

        <div>
          <input
            id="published"
            onChange={() => hanlderChangeWidth()}
            className={`cursor-pointer accent-[#7367f0] appearance-none outline outline-[#7367f0] scale-13 w-5 h-5 bg-none border-2  rounded-full  checked:bg-[#7367f0] checked:duration-0 ${
              navBarWidth || mouseMove
                ? "block opacity-[1]"
                : "hidden opacity-0"
            } group-hover:block  ease duration-[1s]`}
            type="checkbox"
          />
        </div>
      </div>
      <div className="w-[100%] h-[90%] overflow-y-auto scrollbar-hide p-2 flex flex-col ">
        <NavLink
          to={"/dashboard/main"}
          className="flex w-[100%] h-[37px] group/home  text-black dark:text-white p-2 rounded-xl cursor-pointer"
        >
          <div
            className={`flex  w-[100%] ${
              navBarWidth || mouseMove ? "justify-start" : "justify-center"
            }  items-center group-hover/home:mr-2 ease duration-300`}
          >
            <FiHome className={` text-xl`} />
            <p
              className={`${
                navBarWidth || mouseMove
                  ? "opacity-[1] block"
                  : "hidden opacity-0"
              } mr-4 text-sm `}
            >
              الرئيسية
            </p>
          </div>
        </NavLink>
        <div className="flex flex-col w-[100%] mt-2 ">
          <div className="text-sm text-slate-400 h-[37px] mb-4">
            {navBarWidth || mouseMove ? (
              <div className="text-sm w-[100%] h-[100%] text-slate-400 flex justify-start items-center ">
                إدارة المحتوى
              </div>
            ) : (
              <div className="text-xl w-[100%] h-[100%] text-slate-700 flex justify-center items-center">
                ...
              </div>
            )}
          </div>
          <NavLink
            to={"/dashboard/student"}
            className="flex w-[100%] h-[37px] mb-4  group/home text-black dark:text-white p-2 rounded-xl cursor-pointer"
          >
            <div
              className={`flex ${
                navBarWidth || mouseMove ? "justify-start" : "justify-center"
              } w-[100%] items-center group-hover/home:mr-2 ease duration-300`}
            >
              <PiStudent className={` text-xl`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 text-sm `}
              >
                الطلاب
              </p>
            </div>
          </NavLink>
          <NavLink
            to={"/dashboard/teachers"}
            className="flex w-[100%] h-[37px] mb-4  group/home text-black dark:text-white  p-2 rounded-xl cursor-pointer"
          >
            <div
              className={`flex ${
                navBarWidth || mouseMove ? "justify-start" : "justify-center"
              } w-[100%] items-center group-hover/home:mr-2 ease duration-300`}
            >
              <PiChalkboardTeacherLight className={` text-2xl`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4  text-sm `}
              >
                الأساتذة
              </p>
            </div>
          </NavLink>
          <NavLink
            to={"/dashboard/notifications"}
            className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer"
          >
            <div
              className={`flex ${
                navBarWidth || mouseMove ? "justify-start" : "justify-center"
              } w-[100%] items-center group-hover/home:mr-2 ease duration-300`}
            >
              <IoIosNotificationsOutline className={` text-2xl`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4  text-sm `}
              >
                الاشعارات
              </p>
            </div>
          </NavLink>
          <NavLink
            to={"/dashboard/messages"}
            className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer"
          >
            <div
              className={`flex ${
                navBarWidth || mouseMove ? "justify-start" : "justify-center"
              } w-[100%] items-center group-hover/home:mr-2 ease duration-300`}
            >
              <LuMessageSquare className={` text-2xl`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4  text-sm `}
              >
                راسلنا
              </p>
            </div>
          </NavLink>
        </div>
        <div className="flex flex-col w-[100%] mt-2 ">
          <div className="text-sm text-slate-400 h-[37px] mb-4">
            {navBarWidth || mouseMove ? (
              <div className="text-sm w-[100%] h-[37px] text-slate-400 flex justify-start items-center ">
                الاكواد
              </div>
            ) : (
              <div className="text-xl w-[100%] h-[37px] text-slate-700 flex justify-center items-center  ">
                ...
              </div>
            )}
          </div>
          <div className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer">
            <div className="flex justify-start w-[100%] items-center group-hover/home:mr-2 ease duration-300">
              <IoQrCodeOutline className={` text-2xl ease duration-300`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 transition delay-700 ease duration-[2s]  text-sm `}
              >
                رموز التفعيل
              </p>
            </div>
          </div>
          <div className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer">
            <div className="flex justify-start w-[100%] items-center group-hover/home:mr-2 ease duration-300">
              <RiStoreLine className={` text-2xl ease duration-300`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 transition delay-700 ease duration-[2s]  text-sm `}
              >
                نقاط البيع
              </p>
            </div>
          </div>
          <NavLink
            to={"/dashboard/account-statement"}
            className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer"
          >
            <div className="flex justify-start w-[100%] items-center group-hover/home:mr-2 ease duration-300">
              <MdFindReplace className={` text-2xl ease duration-300`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 transition delay-700 ease duration-[2s]  text-sm `}
              >
                كشف حساب
              </p>
            </div>
          </NavLink>
          <div className="flex w-[100%] h-[37px] mb-4 bg group/home text-black dark:text-white p-2 rounded-xl cursor-pointer">
            <div className="flex justify-start w-[100%] items-center group-hover/home:mr-2 ease duration-300">
              <GrUserAdmin className={` text-2xl ease duration-300`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 transition delay-700 ease duration-[2s]  text-sm `}
              >
                المشرفين
              </p>
            </div>
          </div>
        </div>
        {role === 'superAdmin' ? (
          <div className="flex flex-col w-[100%] mt-2 ">
          <div className="text-sm text-slate-400 h-[37px] mb-4">
            {navBarWidth || mouseMove ? (
              <div className="text-sm w-[100%] h-[37px] text-slate-400 flex justify-start items-center ">
                المعلومات
              </div>
            ) : (
              <div className="text-xl w-[100%] h-[37px] text-slate-700 flex justify-center items-center  ">
                ...
              </div>
            )}
          </div>
          <NavLink
            to={"/dashboard/method"}
            className="flex w-[100%] h-[37px] mb-4  group/home text-black dark:text-white p-2 rounded-xl cursor-pointer"
          >
            <div
              className={`flex ${
                navBarWidth || mouseMove ? "justify-start" : "justify-center"
              } w-[100%] items-center group-hover/home:mr-2 ease duration-300`}
            >
              <RiInputMethodFill className={` text-xl`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 text-sm `}
              >
                المواد
              </p>
            </div>
          </NavLink>
          <div className="flex w-[100%] h-[37px] mb-4 group/home text-black dark:text-white p-2 rounded-xl cursor-pointer">
            <div className="flex justify-start w-[100%] items-center group-hover/home:mr-2 ease duration-300">
              <RiStoreLine className={` text-2xl ease duration-300`} />
              <p
                className={`${
                  navBarWidth || mouseMove
                    ? "opacity-[1] block"
                    : "hidden opacity-0"
                } mr-4 transition delay-700 ease duration-[2s]  text-sm `}
              >
                 المناطق
              </p>
            </div>
          </div>
        </div>
        ) : null}
        
      </div>
    </div>
  );
};

export default Sidebar;

// {/* <div  className={`h-[100vh] w-[20%] bg-[#fff] overflow-hidden flex flex-col`}>
// <div className='w-[100%] p-2 h-[10%] flex items-center overflow-hidden'>
//   <ul className='w-[100%] flex justify-between items-center'>
//     <li className='ease duration-[0.8s]'>
//           <input id="published" onChange={handleCheckboxChange} className="transition ease duration-[0.3s] cursor-pointer accent-[#7367f0] appearance-none outline outline-[#7367f0] scale-13 w-5 h-5 bg-none border-2  rounded-full  checked:bg-[#7367f0]" type="checkbox" />
//     </li>
//      <li className='flex '>
//           <span className={`text-[#7367f0] ease duration-[0.8s] font-semibold tracking-[0.01rem] text-[1.45rem] pr-[1rem]`} >FYT</span>

//           <span><img src="/assests/FYT Logo-08.png" alt=""  className='w-[20%]'/></span>
//       </li>
//   </ul>
// </div>
// {/* <div className='w-[100%] p-2 h-[80%] flex items-center overflow-hidden bg-slate-400'>

// </div> */}
// </div> */}

// const [isWideSidebar, setIsWideSidebar] = useState(false);
// const [isChecked, setChecked] = useState(false)

// const handleCheckboxChange = (event) => {
//     setChecked(!isChecked);
// };

// const handleMouseEnter = () => {
//     setIsWideSidebar(true);
//   };

//   const handleMouseLeave = () => {
//     !isChecked?setIsWideSidebar(false):setIsWideSidebar(true)
//   };

//   // w-[260px]
//   <div onMouseEnter={handleMouseEnter}
//   onMouseLeave={handleMouseLeave} className={`w-[${!isWideSidebar ? "75px": "260px"}] bg-[#fff] h-[100%] overflow-hidden hover:w-[260px] ease duration-[0.3s]`}>
//       <div className='w-[260px] pt-[0.35rem] pr-[1.64rem] pb-[0.3rem] pl-[1.64rem] h-[63px] flex items-center overflow-hidden'>
//           {/* .35rem 1.64rem .3rem 1rem; */}
//           <ul className='flex justify-between items-center w-[100%]'>
//               <li className=' flex items-center '>
//                   <span><img className=' max-w-[36px]' src="/assests/startup.ec29495e.png" alt="" /></span>
//                   <span className='text-[#7367f0] font-semibold tracking-[0.01rem] text-[1.45rem] pr-[1rem]' >FYT</span>
//               </li>
//               <li className='w-[26px] '>
//                   <input id="published" value={isChecked} onChange={handleCheckboxChange} className="transition ease duration-[0.3s] cursor-pointer accent-[#7367f0] appearance-none outline outline-[#7367f0] scale-13 w-4 h-4 bg-none border-2  rounded-full  checked:bg-[#7367f0]" type="checkbox" />
//               </li>
//           </ul>
//       </div>
//       <ul>
//           <li className='mt-[0.5rem]'>
//                   <Link  className='active flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <FiHome size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>
//            {/* assists */}
//       {isWideSidebar? (
//         <li className=" text-[#a6a4b0] tracking-[.01rem] leading-[1.5] mt-[2.286rem] mr-[2.2rem] mb-[0.8rem] overflow-hidden text-ellipsis font-[500] text-[12px]">
//           <span>ادارة المحتوى</span>
//         </li>
//       ) : (
//         <li className=" text-[#a6a4b0] tracking-[.01rem] leading-[1.5] mt-[2.286rem] mr-[2.2rem] mb-[0.8rem] overflow-hidden text-ellipsis font-[500] text-[12px]">
//           <span>...</span>
//         </li>
//       )}
//       {/* assists */}

//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <PiStudent size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>
//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <PiChalkboardTeacherLight size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>
//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <IoIosNotificationsOutline size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>
//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <LuMessageSquare size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>

//           {/* assists */}
//               {isWideSidebar ? (
//               <li className=" text-[#a6a4b0] tracking-[.01rem] leading-[1.5] mt-[2.286rem] mr-[2.2rem] mb-[0.8rem] overflow-hidden text-ellipsis font-[500] text-[12px]">
//                   <span></span>
//               </li>
//               ) : (
//               <li className=" text-[#a6a4b0] tracking-[.01rem] leading-[1.5] mt-[2.286rem] mr-[2.2rem] mb-[0.8rem] overflow-hidden text-ellipsis font-[500] text-[12px]">
//                   <span>...</span>
//               </li>
//               )}

//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <IoQrCodeOutline size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>

//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <RiStoreLine size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>

//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <MdFindReplace size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>

//           <li className='mt-[0.5rem]'>
//                   <Link  className='flex items-center text-ellipsis rounded-[4px] overflow-hidden text-[#625f6e] pt-[10px] pb-[10px] pl-[15px] pr-[15px] ml-[15px] mr-[15px] font-normal'>
//                   <div className='ml-[10px]'>
//                       <GrUserAdmin size={20}/>
//                   </div>
//                   {isWideSidebar && <span></span>}
//               </Link>
//           </li>

//       </ul>
//       {/*
//   text-overflow: ellipsis;
//   overflow: hidden;
//       color: #625f6e;
//   line-height: 1.45;    padding: 10px 15px 10px 15px;    margin: 0 15px;color: #fff;
//   font-weight: 400;border-radius: 4px;background: linear-gradient(-118deg, #7367f0, rgba(115, 103, 240, .7));
// }

//   */}
//   </div>
