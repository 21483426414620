import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
  name: "students",
  initialState: {
    students: [],
    numberOfStudents: 0,
    isOpenedStudents: false,
    loading: false,
  },
  reducers: {
    setStudents(state, action) {
    console.log(state.students)
      state.students = action.payload;
    },
    setNumberOfStudent(state, action) {
      state.numberOfStudents = action.payload;
    },
    removeStudent(state, action) {
      const noti = action.payload;
      state.students = state.students.filter(
        (student) => student.id !== noti
      );
    },
    setIsOpenedStudent(state) {
      state.isOpenedStudents = !state.isOpenedStudents;
    },
    setLoading(state) {
      state.loading = !state.loading;
    },
    addStudent(state, action) {
      state.students.push(action.payload)
    }
  },
});

const studentsReducer = studentSlice.reducer;
const studentsAction = studentSlice.actions;

export { studentsAction, studentsReducer };
