import "./Pagination.css";
import { FaChevronRight, FaAngleLeft } from "react-icons/fa6";
export const Pagination = ({ pages, currentPage, setCurrentPage }) => {
  const generatedPage = [];
  for (let i = 1; i <= pages; i++) {
    generatedPage.push(i);
  }
  return (
    <div className="pagination">
      <button
        onClick={() => setCurrentPage((current) => current - 1)}
        disabled={currentPage === 1}
        className="page previous"
      >
        <FaChevronRight size={15}/>
      </button>
      {generatedPage.map((page) => (
        <div
          onClick={() => setCurrentPage(page)}
          key={page}
          className={`${currentPage === page ? "page pagi activePagi" : "page"} dark:bg-slate-800 dark:text-white`}
        >
          {page}
        </div>
      ))}
      <button
        onClick={() => {
          setCurrentPage((current) => current + 1);
        }}
        disabled={currentPage === pages}
        className="page next"
      >
        <FaAngleLeft size={15}/>
      </button>
    </div>
  );
};
